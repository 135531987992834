var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Locations"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Work Order Templates","sub-heading":"Work Order Templates allow you to quickly create frequently used work orders or schedule recurring work."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search work orders..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.isFiltering)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                              name: 'create-work-order-template'
                            }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Template")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.work_order_templates.data),function(workOrder){return _c('div',{key:workOrder.uuid,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"is-block",attrs:{"to":{
                              name: 'work-order-template-manager',
                              params: {
                                template: workOrder.id
                              }
                            }}},[_vm._v(" "+_vm._s(workOrder.name)+" "+_vm._s(workOrder.reference)+" "),(workOrder.schedule.interval)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`This template creates work orders on a recurring basis`),expression:"`This template creates work orders on a recurring basis`"}],staticClass:"tag is-small is-warning"},[_vm._v(" recurring ")]):_vm._e()]),_c('small',{staticClass:"has-text-grey"},[_vm._v("For "+_vm._s(workOrder.customer.name)+" at "+_vm._s(workOrder.location.name)+", Updated "+_vm._s(_vm._f("asCalendar")(workOrder.updated_at)))])],1),_c('column',{staticClass:"is-narrow is-flex is-align-items-center is-justify-content-end"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('The team assigned to this Work Order'),expression:"'The team assigned to this Work Order'"}]},[_c('icon',{staticClass:"has-text-grey mr-1",attrs:{"icon":"people-arrows"}}),_c('span',[_vm._v(_vm._s(workOrder.team.name))])],1)]),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-center"},[_c('priority-indicator',{attrs:{"priority":workOrder.priority}})],1),_c('column',{staticClass:"is-narrow is-flex is-align-items-center is-justify-content-end"},[_c('outcome-tag',{attrs:{"status":workOrder.status}})],1)],1)],1)}),0)])],1),(_vm.work_order_templates.data.length)?_c('pager',{attrs:{"pageable":_vm.work_order_templates,"context":"Work Order","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.work_order_templates.data.length,"with-link":"","heading":"There are no work order templates available for display.","to":{
          name: 'create-work-order-template'
        },"to-text":"New Work Order Template"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }